import Vue from "vue";
import Cookies from "js-cookie";
import router from "@/router";

const state = {
  user: null,
  categories: [],
  skills: [],
  questions: [],
  assessments: [],
  formUsers: [],
  users: [],
  categories: [],
  skillsbyCat: [],
  filteredUsers: null,
  singleUser: null,
  allJobs: [],
  singleJob: null,
  allClients: [],
  singleClients: null,
  clientJobs: [],
  filteredJobs: [],
  matchConfig: [],
  internalTeam: [],
  page: 1,
  clientPage: 1,
  dashboardData: null,
  talentsByCategory: 0
};
const getters = {
  getUser: (state) => state.user,
  getCategories: (state) => state.categories,
  getSkills: (state) => state.skills,
  getQuestions: (state) => state.questions,
  getAssessments: (state) => state.assessments,
  getFormUsers: (state) => state.formUsers,
  getUsers: (state) => state.users,
  getCategories: (state) => state.categories,
  getskillsbyCat: (state) => state.skillsbyCat,
  getFilteredUsers: (state) => state.filteredUsers,
  getSingleUser: (state) => state.singleUser,
  getallJobs: (state) => state.allJobs,
  getsingleJob: (state) => state.singleJob,
  getallClients: (state) => state.allClients,
  getsingleClients: (state) => state.singleClients,
  getclientJobs: (state) => state.clientJobs,
  getFilteredJobs: (state) => state.filteredJobs,
  getMatchConfig: (state) => state.matchConfig,
  getInternalTeam: (state) => state.internalTeam,
  getPage: (state) => state.page,
  getClientPage: (state) => state.clientPage,
  getDashboardData: (state) => state.dashboardData,
  getTalentsByCategory: (state) => state.talentsByCategory
};
const mutations = {
  SETUser: (state, user) => {
    state.user = user;
  },
  SETCategories: (state, value) => {
    state.categories = value;
  },
  SETSkills: (state, value) => {
    state.skills = value;
  },
  SETQuestions: (state, value) => {
    state.questions = value;
  },
  SETAssessments: (state, value) => {
    state.assessments = value;
  },
  SETFormUsers: (state, value) => {
    state.formUsers = value;
  },
  setUsers: (state, value) => {
    state.users = value;
  },
  SETCategories: (state, value) => {
    state.categories = value;
  },
  setSkillsbyCat: (state, value) => {
    state.skillsbyCat = value;
  },
  setFiltered: (state, value) => {
    state.filteredUsers = value;
  },
  setsingeUser: (state, value) => {
    state.singleUser = value;
  },
  setallJobs: (state, value) => {
    state.allJobs = value;
  },
  setSingleJobs: (state, value) => {
    state.singleJob = value;
  },
  setallClients: (state, value) => {
    state.allClients = value;
  },
  setsingleClients: (state, value) => {
    state.singleClients = value;
  },
  setClientJobs: (state, value) => {
    state.clientJobs = value;
  },
  setFilteredJobs: (state, value) => {
    state.filteredJobs = value;
  },
  setmatchConfigs: (state, value) => {
    state.matchConfig = value;
  },
  SETInternalTeam: (state, value) => {
    state.internalTeam = value;
  },
  UPDATEInternalTeam: (state, value) => {
    state.internalTeam = state.internalTeam.map((item) => {
      if (item._id == value._id) {
        return value;
      }
      return item;
    });
  },
  AddInternalTeam: (state, value) => {
    state.internalTeam.push(value);
  },
  DeleteInternalTeam: (state, value) => {
    state.internalTeam = state.internalTeam.filter(
      (item) => item._id != value
    );
  },
  SETPage: (state, value) => {
    state.page = value;
  },
  SETClientPage: (state, value) => {
    state.clientPage = value;
  },
  SETDashboardData: (state, value) => {
    state.dashboardData = value;
  },
  setTalentsBuCategory: (state, value) => {
    state.talentsByCategory = value;
  }
};
const actions = {

  async Login({ commit }, payload) {
    const user = await Vue.axios.post(
      "/authenticate/internalTeam/login",
      payload
    );
   
    if (user.data.user.role == "ADMIN" || user.data.user.role == "SALES" || user.data.user.role == "TALENT-SPECIALIST") {
      Cookies.set("token", user.data.token);
      commit("SETUser", user.data.user);
      return user;
    }
    return null;
  },
  async logout({ commit }) {
    commit("SETUser", null);
    Cookies.remove("token");
  },
  async uploadProfilePicture(_, payload) {
    try {
      await Vue.axios.patch("/internalTeam/uploadProfileImage", payload);
    } catch (error) {
      
    }
  },
  async fetchUser({ commit }) {
    try {
      const user = await Vue.axios.get("/user/getUserByToken");
      commit("SETUser", user.data.user);
    } catch (error) {
      Cookies.remove("token");
      router.push("/login");
      
    }
  },
  async fetchDashboardData({ commit }) {
    
    const [
      numberOfTalents,
      completeProfileTalents,
      numberOfFemaleTalents,
      numberOfMaleTalents,
      numberOfClients,
      numberOfJobs,
      matchedJobs,
      unmatchedJobs,
      matchedUnpaidJobs,
    ] = await Promise.all([
      Vue.axios.get("/dashboard/getNumberOfTalents"),
      Vue.axios.get("/dashboard/getCompleteProfileTalents"),
      Vue.axios.get("/dashboard/getNumberOfFemaleTalents"),
      Vue.axios.get("/dashboard/getNumberOfMaleTalents"),
      Vue.axios.get("/dashboard/getNumberOfClients"),
      Vue.axios.get("/dashboard/getNumberOfJobs"),
      Vue.axios.get("/dashboard/getMatchedJobs"),
      Vue.axios.get("/dashboard/getUnmatchedJobs"),
      Vue.axios.get("/dashboard/getMatchedUnpaidJobs"),
    ]);

    commit("SETDashboardData", {
      talents: numberOfTalents.data.talents,
      completeProfileTalents: completeProfileTalents.data.talents,
      numberOfFemaleTalents: numberOfFemaleTalents.data.talents,
      numberOfMaleTalents: numberOfMaleTalents.data.talents,
      numberOfClients: numberOfClients.data.clients,
      numberOfJobs: numberOfJobs.data.jobs,
      matchedJobs: matchedJobs.data.jobs,
      unmatchedJobs: unmatchedJobs.data.jobs,
      matchedUnpaidJobs: matchedUnpaidJobs.data.jobs,

    });
  },
  async fetchTalentsByCategory({commit}, payload) {
    const talents = await Vue.axios.get(`/dashboard/getTalentInCategory/${payload}`);
    commit("setTalentsBuCategory", talents.data.talents);
  },

  async fetchInternalTeam({ commit }) {
    try {
      const internalTeam = await Vue.axios.get("/internalTeam/getAll");
      commit("SETInternalTeam", internalTeam.data.user);
    } catch (error) {}
  },

  async deleteInternalTeam({ commit }, payload) {
    await Vue.axios.delete(`/internalTeam/delete/${payload}`);
    commit("DeleteInternalTeam", payload);
  },

  async editInternalTeam({ commit }, payload) {
    await Vue.axios.patch(
      `/internalTeam/updateProfile/${payload._id}`,
      payload
    );
    commit("UPDATEInternalTeam", payload);
  },
  async setPage({commit}, payload) {
    commit("SETPage", payload);
    
  },
  async setClientPage({commit}, payload) {
    commit("SETClientPage", payload);
  },
  async updatePassword(_, payload)
  {
    await Vue.axios.patch(
      `/internalTeam/updatePassword/${payload.id}`,
      {
        password: payload.password
      }
    );

  },

  async addInternalTeam({ commit }, payload) {
    delete payload._id
    const internalTeam = await Vue.axios.post(
      "/authenticate/internalTeam/register",
      payload
    );
    commit("AddInternalTeam", internalTeam.data.user);
  },

  async addCategory(_, payload) {
    await Vue.axios.post("/category", { name: payload });
  },
  async fetchCaegories({ commit }) {
    const cats = await Vue.axios.get("/category");
    commit("SETCategories", cats.data.categories);
  },
  async deleteCategory({ dispatch }, payload) {
    await Vue.axios.delete(`/category/${payload}`);
    dispatch("fetchCaegories");
  },
  async fetchSingleCategory(_, id) {
    const cat = await Vue.axios.get(`/category/${id}`);
    return cat.data.category;
  },
  async editCategory(_, payload) {
    await Vue.axios.patch(`/category/${payload.id}`, { name: payload.name });
  },
  async addSkill(_, payload) {
    await Vue.axios.post("/skill", payload);
  },
  async fetchSkills({ commit }) {
    const skills = await Vue.axios.get("/skill/getAll");
    commit("SETSkills", skills.data.skills);
  },
  async deleteSkill({ dispatch }, payload) {
    await Vue.axios.delete(`/skill/${payload}`);
    dispatch("fetchSkills");
  },
  async fetchSingleSkill(_, id) {
    const skill = await Vue.axios.get(`/skill/${id}`);
    return skill.data.skill;
  },
  async editSkill(_, payload) {
    await Vue.axios.put(`/skill/${payload.id}`, payload.data);
  },
  async addQuestion(_, payload) {
    await Vue.axios.post("/question", payload);
  },
  async fetchQuestions({ commit }) {
    const questions = await Vue.axios.get("/question");
    commit("SETQuestions", questions.data.questions);
  },
  async deleteQuestion({ dispatch }, payload) {
    await Vue.axios.delete(`/question/${payload}`);
    dispatch("fetchQuestions");
  },
  async fetchSingleQuestion(_, id) {
    const question = await Vue.axios.get(`/question/${id}`);
    return question.data.question;
  },
  async editQuestion(_, payload) {
    await Vue.axios.patch(`/question/${payload.id}`, payload.data);
  },
  async addAssessment(_, payload) {
    await Vue.axios.post("/assessment", payload);
  },
  async fetchAssessments({ commit }) {
    const assessment = await Vue.axios.get("/assessment/format");
    commit("SETAssessments", assessment.data.assessment);
  },
  async deleteAssessment({ dispatch }, payload) {
    await Vue.axios.delete(`/assessment/${payload}`);
    dispatch("fetchAssessments");
  },
  async fetchSingleAssessment(_, id) {
    const assessment = await Vue.axios.get(`/assessment/${id}`);
    return assessment.data.assessment;
  },
  async editAssessment(_, payload) {
    await Vue.axios.patch(`/assessment/${payload.id}`, payload.data);
  },
  async addUser(_, payload) {
    await Vue.axios.post(`/authenticate/registerFormUser`, payload);
  },
  async fetchFormUsers({ commit }) {
    const formUsers = await Vue.axios.get("/user/formUsers");
    commit("SETFormUsers", formUsers.data.users);
  },
  async sendEmail(_, payload) {
    await Vue.axios.post("/authenticate/emailFormUser", payload);
  },
  async fetchUsers({ commit }) {
   try {
    const users = await Vue.axios.get("/user/freelancer");
    commit("setUsers", users.data.user);
   } catch (error) {
    
   }
  },
  async fetchCategories({ commit }) {
    const categories = await Vue.axios.get("/category");
    commit("SETCategories", categories.data.categories);
  },
  async fetchSkillsbyCat({ commit }, payload) {
    commit("setSkillsbyCat", []);
    const skills = await Vue.axios.get(`/skill/getSkillByCategory/${payload}`);
    commit("setSkillsbyCat", skills.data.skills);
  },
  async filterUsers({ commit }, payload) {
   try {
    const filter = await Vue.axios.post(`/user/filterFreelancer`, payload);
    commit("setFiltered", filter.data.talent);
   } catch (error) {
    commit("setFiltered", []);
    
   }
  },
  async fetchSingleUser({ commit }, id) {
    const user = await Vue.axios.get(`/user/getFreelancer/${id}`);
    commit("setsingeUser", user.data);
  },
  async updateSabiTalent({ dispatch }, payload) {
    await Vue.axios.patch(`/user/updateSabiTalent/${payload.id}`, payload.data);
    dispatch("fetchSingleUser", payload.id);
  },
  async removeResultPlatform(_, payload) {
    await Vue.axios.delete(`/user/removeResultFile/${payload.user}/${payload.id}`);
  }, 
  async updateResultPlatform(_, payload) {
    await Vue.axios.patch(`/user/updateResultFile/${payload.user}/${payload.id}`, payload.data);
  },

  async addResultPlatform(_, payload) {
    await Vue.axios.post(`/user/addResultFile/${payload.user}`, payload.data);
  },
  async addSkilltoUser(_, payload) {
    await Vue.axios.patch(`/user/addTalentLevel/${payload.user}`, payload.data);
  },
  async fetchAllJobs({ commit }) {
    const allJobs = await Vue.axios.get(`/client/jobs/getAll`);
    commit("setallJobs", allJobs.data.jobs);
  },
  async fetchSigleJob({ commit }, payload) {
    const singleJob = await Vue.axios.get(`/client/getJobById/${payload}`);
    commit("setSingleJobs", singleJob.data.job);
  },
  async fetchAllClients({ commit }) {
    const allClients = await Vue.axios.get(`/user/client`);
    commit("setallClients", allClients.data.users);
  },
  async fetchSigleClient({ commit }, payload) {
    const singleClients = await Vue.axios.get(`/user/clientById/${payload}`);
    commit("setsingleClients", singleClients.data);
    const clientJobs = await Vue.axios.get(`/client/getClientJobs/${payload}`);
    commit("setClientJobs", clientJobs.data.jobs);
  },
  async filterJob({ commit }, payload) {
    const filteredJobs = await Vue.axios.post(`/client/filterJobs`, payload);
    commit("setFilteredJobs", filteredJobs.data.jobs);
  },
  async fetchMatchConfigs({ commit }, payload) {
    const matchConfigs = await Vue.axios.get(`/matchConfig`);
    commit("setmatchConfigs", matchConfigs.data.matchConfig);
  },
  async AddMatchConfig({ dispatch }, payload) {
    await Vue.axios.post(`/matchConfig`, payload);
    dispatch("fetchMatchConfigs");
  },
  async DeleteMatch({ dispatch }, payload) {
    await Vue.axios.delete(`/matchConfig/${payload}`);
    dispatch("fetchMatchConfigs");
  },
  async EditMatchConfig({ dispatch }, payload) {
    await Vue.axios.patch(`/matchConfig/${payload.id}`, payload);
    dispatch("fetchMatchConfigs");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
