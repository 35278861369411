import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    component: () => import("@/Root"),
    path:"/",
   children:[
    {
      path:"/",
      name:"Home",
      component:() => import("@/views/home.vue")
    }
   ]

  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Auth/Login.vue"),
  },
  {
    path: "/users",
    redirect: "/talents",
  },
  {
    path: "/users/:id",
    redirect: "/talents/:id",
  },
  
  {
    path:'/talents',
    name:'Talents',
    component: () => import("@/Root"),
    children: [
      {
        path: "",
        name: "TalentsList",
        component: () => import("@/views/Users/index.vue"),
      },
      {
        path: ":id",
        name: "User",
        component: () => import("@/views/Users/user.vue"),
      },
    ],
  },
  {
    path: "/internal-team",
    name: "InternalTeam",
    component: () => import("@/Root"),
    children: [
      {
        path: "",
        name: "Index",
        component: () => import("@/views/Internal-Team/index.vue"),
      },
      
    ],
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("@/Root"),
    children: [
      {
        path: "",
        name: "Index",
        component: () => import("@/views/Categories/Index.vue"),
      },
      {
        path: "add",
        name: "Add",
        component: () => import("@/views/Categories/Add.vue"),
      },
      {
        path: ":id",
        name: "Edit",
        component: () => import("@/views/Categories/Edit.vue"),
      },
    ],
  },
  {
    path: "/skills",
    name: "Skills",
    component: () => import("@/Root"),
    children: [
      {
        path: "",
        name: "Index",
        component: () => import("@/views/Skills/Index.vue"),
      },
      {
        path: "add",
        name: "AddSkill",
        component: () => import("@/views/Skills/Add.vue"),
      },
      {
        path: ":id",
        name: "EditSkill",
        component: () => import("@/views/Skills/Edit.vue"),
      },
    ],
  },
  {
    path: "/questions",
    name: "Questions",
    component: () => import("@/Root"),
    children: [
      {
        path: "",
        name: "Index",
        component: () => import("@/views/Questions/Index.vue"),
      },
      {
        path: "add",
        name: "Add",
        component: () => import("@/views/Questions/Add.vue"),
      },
      {
        path: ":id",
        name: "Edit",
        component: () => import("@/views/Questions/Edit.vue"),
      },
    ],
  },
  {
    path: "/assessments",
    name: "Assessments",
    component: () => import("@/Root"),
    children: [
      {
        path: "",
        name: "Index",
        component: () => import("@/views/Assessments/Index.vue"),
      },
      {
        path: "add",
        name: "Add",
        component: () => import("@/views/Assessments/Add.vue"),
      },
      {
        path: ":id",
        name: "Edit",
        component: () => import("@/views/Assessments/Edit.vue"),
      },
    ],
  },
  {
    path: "/users-from-form",
    name: "UsersFromForm",
    component: () => import("@/Root"),
    children: [
      {
        path: "",
        name: "Index",
            component: () => import("@/views/UsersFromForm/index.vue"),
      },
      {
        path: "add",
        name: "Add",
        component: () => import("@/views/UsersFromForm/Add.vue")
      },
      
    ],
  },
  {
    path: "/jobs",
    name: "UsersFromForm",
    component: () => import("@/Root"),
    children: [
      {
        path: "",
        name: "jobs",
            component: () => import("@/views/job/jobs.vue"),
      },
      {
        path: ":id",
        name: "job",
            component: () => import("@/views/job/job.vue"),
      },
      
    ],
  },
  {
    path: "/clients",
    name: "UsersFromForm",
    component: () => import("@/Root"),
    children: [
      {
        path: "",
        name: "Clients",
            component: () => import("@/views/Client/index.vue"),
      },
      {
        path: ":id",
        name: "client",
            component: () => import("@/views/Client/client.vue"),
      },
      
    ],
  },
  {
    path: "/match-config",
    name: "MatchConfig",
    component: () => import("@/Root"),
    children: [
      {
        path: "",
        name: "Index",
            component: () => import("@/views/match/index.vue"),
      },
      
      
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
