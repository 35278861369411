
import Cookies from 'js-cookie'
import axios from 'axios';

const AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  
  headers: {
    Accept: 'application/json'
  }   
})

AxiosInstance.interceptors.request.use((config) => {
  if (Cookies.get('token')){
    config.headers["Authorization"] = "Bearer " + Cookies.get('token')
  }
  return config
})

export default AxiosInstance